import { createTV } from 'tailwind-variants'

const config = {
	override: {
		classGroups: {
			// Class groups are defined here
			'font-size': [
				{
					text: [
						'input-mobile',
						'headline-small',
						'headline',
						'h1',
						'h1-mobile',
						'h2',
						'h2-mobile',
						'h3',
						'h3-mobile',
						'xs-mobile',
						'xs',
						's-mobile',
						's',
						'm-mobile',
						'm',
						'l-mobile',
						'l',
					],
				},
			],
			'text-color': [
				{
					text: [
						'white',
						'black',
						'mainPink',
						'lightPink',
						'darkGrey',
						'grey',
						'onyx',
						'davyGrey',
						'lightGrey',
						'placeholder',
						'textGrey',
					],
				},
			],
		},
	},
}

export const tv = createTV({
	twMerge: true,
	twMergeConfig: config,
})
