import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		header:
			'col-span-4 lg:col-span-6 grid grid-cols-4 gap-x-2 lg:grid-cols-6 lg:gap-x-4 items-start px-2 md:px-4',
		tagText: 'col-span-4 lg:col-span-6 mb-1',
		headline: 'col-span-4',
	},
	variants: {
		noPx: {
			true: {
				header: 'px-0 md:px-0',
			},
		},
		fullWidth: {
			true: {
				headline: 'col-span-4 lg:col-span-6 max-w-full shrink-0',
			},
		},
		theme: {
			pink: {
				highlightStyle: 'text-white',
				headline: 'text-black',
			},
			dark: {
				highlightStyle: 'text-mainPink',
				headline: 'text-white',
			},
			light: {
				highlightStyle: 'text-mainPink',
				headline: 'text-black',
			},
		},
	},
})

export type StylesVariants = VariantProps<typeof styles>
