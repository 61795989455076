import { SanityAsset } from '@sanity/image-url/lib/types/types';

export type SeoFieldsProps = {
  seo: {
    seoTitle: string;
    seoDescription: string;
    seoImage: SanityAsset;
  }
}

export const seoFields = `
  seo{
    seoDescription,
    seoTitle,
    seoImage
  }
`;
