import { PortableText, PortableTextComponents } from 'next-sanity'
import { Tag } from '@otomoro/components/shared/Tag'
import { AppTitle } from '@otomoro/components/shared/AppTitle'

import { styles } from './SectionHeader.css'
import { PortableTextObject } from 'sanity'
import clsx from 'clsx'

type SectionHeaderProps = {
	tag?: string
	title?: PortableTextObject
	description?: PortableTextObject
	noPx?: boolean
	fullWidth?: boolean
	theme?: 'pink' | 'light' | 'dark'
	descClassNames?: string
	className?: string
	tagClass?: string
	children?: React.ReactNode
}

export const SectionHeader = ({
	tag,
	tagClass,
	title,
	noPx,
	fullWidth,
	className,
	children,
	theme = 'light',
}: SectionHeaderProps) => {
	const { tagText, header, headline } = styles()

	const titleComponents = {
		block: {
			normal: ({ children }: any) => (
				<AppTitle
					as="h2"
					variant="h1"
					textTransform="uppercase"
					className={headline({ fullWidth })}
					color={theme === 'dark' ? 'white' : 'black'}
				>
					{children}
				</AppTitle>
			),
		},
		marks: {
			highlight: ({ children }: any) => (
				<span className={theme === 'pink' ? 'text-white' : 'text-mainPink'}>{children}</span>
			),
		},
	} as PortableTextComponents

	return (
		<header className={clsx(header({ noPx }), className)}>
			{tag && (
				<Tag theme={theme} as="strong" className={clsx(tagText(), tagClass)}>
					{tag}
				</Tag>
			)}
			{title && (
				<PortableText value={title} components={titleComponents} />
			)}
			{children && (
				<AppTitle
					as="h2"
					variant="h1"
					textTransform="uppercase"
					className={headline({ fullWidth })}
					color={theme === 'dark' ? 'white' : 'black'}
				>
					{children}
				</AppTitle>
			)}
		</header>
	)
}
