import { VariantProps } from 'tailwind-variants';
import { tv } from '@otomoro/helpers/mergeClasses';

export const text = tv({
	base: '',
	variants: {
		size: {
			button: 'text-button-mobile md:text-button',
			notice: 'text-xs-mobile md:text-s',
			small: 'text-s-mobile md:text-s',
			base: 'text-s-mobile md:text-base',
			medium: 'text-m-mobile md:text-m',
			large: 'text-l-mobile md:text-l',
			intro: 'text-intro-mobile md:text-intro',
			nav: 'text-nav',
			h3: 'text-h3-mobile md:text-h3',
			h2: 'text-h2-mobile md:text-h2',
			quote: 'text-s md:text-quote',
			footerTitle: 'text-h3-mobile md:text-h2'
		},
		weight: {
			bold: 'font-bold',
			medium: 'font-medium',
			regular: 'font-normal'
		},
		family: {
			familjen: 'font-familjen',
			helvetica: 'font-helvetica',
			condensed: 'font-condensed',
		},
		color: {
			white: 'text-white',
			black: 'text-black',
			mainPink: 'text-mainPink',
			lightPink: 'text-lightPink',
			darkGrey: 'text-darkGrey',
			grey: 'text-grey',
			onyx: 'text-onyx',
			davyGrey: 'text-davyGrey',
			lightGrey: 'text-lightGrey',
			textGrey: 'text-textGrey',
		},
		decoration: {
			underline: 'underline underline-offset-1',
		}
	}
})

export type TextVariants = VariantProps<typeof text>
