import Link, { LinkProps } from 'next/link'
import {
	cloneElement,
	createElement,
	forwardRef,
	LinkHTMLAttributes,
	ReactComponentElement,
	ReactNode,
	Ref,
} from 'react'
import { AppText } from '@otomoro/components/shared/AppText'
import { link, LinkVariants } from './AppLink.css'
import clsx from "clsx"

export interface AppLinkProps extends LinkHTMLAttributes<HTMLAnchorElement>, LinkVariants {
	as?: 'button' | 'a' | 'nextLink' | 'summary'
	children?: ReactNode | ReactNode[]
	className?: string
	disabled?: boolean
	fullWidth?: boolean
	nextLinkProps?: Partial<LinkProps>
	target?: '_blank' | '_self' | '_parent' | '_top'
	textProps?: any
	arrow?: boolean
}
export const AppLink = forwardRef(
	(
		{
			as = 'nextLink',
			children,
			className,
			arrow = false,
			disabled = false,
			fullWidth = false,
			href,
			nextLinkProps,
			textProps,
			onClick,
			...rest
		}: AppLinkProps,
		ref: Ref<any>
	) => {
		const childrenArray: any[] = []

		Array.isArray(children)
			? childrenArray.concat(children)
			: childrenArray.push(
				<AppText key="text" {...textProps} className={clsx(link({arrow}), textProps?.className)}>
					{children}
				</AppText>
			)

		if (as === 'nextLink' && href) {
			return (
				<Link
					href={href}
					className={clsx(
						disabled ? 'disabled' : undefined,
						className
					)}
					ref={ref}
					onClick={onClick}
					{...nextLinkProps}
					{...rest}
				>
					{childrenArray}
				</Link>
			)
		}

		return createElement(
			as,
			{
				ref,
				href,
				className: clsx(
					disabled ? 'disabled' : undefined,
					className
				),
				onClick: onClick,
				...rest,
			},
			childrenArray
		)
	}
)

AppLink.displayName = 'AppLink';
