import clsx from "clsx";
import { createElement, CSSProperties, forwardRef, HTMLAttributes, ReactNode, Ref } from 'react'
import { TextVariants, text } from "./AppText.css";

export interface AppTextProps extends HTMLAttributes<Element>, TextVariants {
	as?: 'p' | 'span' | 'strong' | 'div' | 'code' | 'dt' | 'label' | 'dd' | 'legend' | 'h2' | 'h3' // defaults to span
	children: ReactNode
	ellipsis?: boolean
	textAlign?: CSSProperties['textAlign']
	textTransform?: CSSProperties['textTransform']
	whitespace?: 'normal' | 'nowrap' | 'pre'
	htmlFor?: string
	fullWidth?: boolean
	fontStyle?: CSSProperties['fontStyle']
	bold?: boolean
	className?: string
	color?: 'white' | 'black' | 'mainPink' | 'lightPink' | 'grey' | 'darkGrey' | 'onyx' | 'davyGrey' | 'lightGrey' |'textGrey'
}

export const AppText = forwardRef(
	({
	as = 'span',
	color = 'black',
	children,
	ellipsis = false,
	fullWidth,
	textAlign,
	textTransform,
	decoration,
	whitespace,
	htmlFor,
	fontStyle,
	className,
	size = 'medium',
	weight,
	family = 'helvetica',
	...rest
}: AppTextProps,
ref: Ref<any>) => {
	return createElement(
		as,
		{
			...rest,
			ref,
			htmlFor,
			style: {
				...(ellipsis
					? {
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
					}
					: {}),
				...rest.style,
			},
			className: clsx(
				text({ size, weight, family, color, decoration }),
				{ 'w-full': fullWidth },
				{ [`${textTransform}`]: textTransform },
				{ [`${fontStyle}`]: fontStyle },
				{ [`whitespace-${whitespace}`]: whitespace },
				{ [`text-${textAlign}`]: textAlign },
				className),
		},
		children
	)
})

AppText.displayName = 'AppText';
