import { ButtonFields } from './buttonFields.groq'
import { downloadField } from './downloadField.groq'

export const flexibleContent = `
	flexibleContent{
		...,
		_type,
		(_type == "cardsSlider") => {
      title,
			headline,
			products[] {
				_key,
        _type == 'productCard' => @-> {
					_type,
					"slug": slug.current,
					title,
					formula,
					productLogo->{
						svg,
						title,
						_type
					},
					marketThumbnail,
				},
        _type != 'productCard' => @ {
          title,
          image
				}
			}
		},
		(_type == "textHighlight") => {
      title,
			headline,
			introduction[]{
				...,
				children[]{
					...,
					_type == 'reference' => {
						...,
						"svg": @->.svg
					}
				}
			},
		},
		(_type == "highlightedCards") => {
			_key,
      title,
      headline,
			expandedMarkets,
			formOption,
			markets[]->{
				_id,
				slug,
				title,
				description,
				thumbnail,
			},
			cards[]{
				title,
				description,
				image{
					...,
					"lqip": asset->metadata.lqip,
				},
				link{
					title,
					reference->{
						_type,
						"slug": slug.current,
					},
					anchor
				}
			},
			${downloadField},
		},
		(_type == "highlightedNews") => {
      tag,
      headline,
      description,
			news[]->{
				_id,
				_type,
				"slug": slug.current,
				title,
				duration,
				link[0]{
          url,
          newWindow,
        },
			}
		},
		(_type == "highlightedSection") => {
      tag,
      headline,
      description,
      button[0]{
				${ButtonFields}
      }
		},
		(_type == "imageHighlight") => {
      headline,
			image,
      logo->{
				svg,
			},
		},
		(_type == "alternateSection") => {
      _key,
      tag,
      headline,
      description,
      image,
      alternateSide,
      theme,
      descriptionPairs,
      descriptionType,
      showDL,
      showLogo,
      cardLogo->{
				svg,
        title,
        _type
			},
      button[0]{
				${ButtonFields}
      },
			${downloadField}
		},
		(_type == "highlightArticle") => {
      tag,
      headline,
      showArticle,
      article->{
        image {
         ...,
				"src": asset->url
        },
        title,
        description,
        duration,
        publishedAt,
        _id,
        _type,
        "slug": slug.current,
				link[0]{
					${ButtonFields}
				},
			},
      partnersLogo[]->{
				title,
				svg,
				_id,
				source,
			}
		},
		(_type == "investors") => {
			tag,
			title,
      investors[]->{
        _id,
        image,
        title,
			},
		},
		(_type == "goals") => {
			tag,
			title,
			description,
			goals[] {
				title,
        icon->{
          title,
          svg
        }
			}
		},
		(_type == "promotionalCard") => {
			tag,
			title,
			description,
      button[0]{
				${ButtonFields}
      }
		},
		(_type == "singleImage") => {
			_key,
			image,
			displayText,
			tag,
			headline,
			content,
			displayForm,
      labelBtn,
			${downloadField}
		},
		(_type == "properties") => {
      _key,
			tag,
			title,
			description,
			properties,
      display3d,
      illustration,
			model3d {
        asset-> {
					url
				}
      },
			poster {
				image {
					asset-> {
						url
					}
				}
      },
      techSheet {
        asset-> {
					url
				}
      },
      ${downloadField}
		},
		(_type == "bannerCards") => {
      tag,
      headline,
			products[]->{
				_id,
				_type,
				"slug": slug.current,
				title,
        formula,
				productLogo->{
					svg,
					title,
					_type
				},
			}
		},
	}[],
`
