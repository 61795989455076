import { VariantProps } from 'tailwind-variants';
import { tv } from '@otomoro/helpers/mergeClasses';

export const link = tv({
	base: '',
	variants: {
		arrow: {
      true: 'after:content-["↗"] after:inline-block after:underline',
      false: '',
    }
	}
})

export type LinkVariants = VariantProps<typeof link>
