import { VariantProps } from 'tailwind-variants'
import { tv } from '@otomoro/helpers/mergeClasses'

export const button = tv({
	base: 'px-[1.2rem] rounded-full relative transition-colors [&.button-link>*]:transition-colors duration-100 inline-block group',
	variants: {
		size: {
			singleSmall: '!h-[3.2rem] !w-[3.2rem]',
			small: 'flex-shrink-0 py-1 md:px-[1.2rem]',
			normal: 'p-[1.2rem] md:py-[1.2rem] md:px-[2rem]',
			gallery: 'shrink-0 py-1 px-2 mx-1 md:mx-[1.5rem]'
		},
		variant: {
			primary: 'bg-mainPink text-black hover:bg-darkPink group-hover:bg-darkPink',
			secondary: 'bg-black text-white after:text-white',
			ternary: 'bg-white text-black after:text-black',
			outline:
				'border-black border text-black [&.button-link>*]:text-black hover:border-mainPink [&.button-link>*]:hover:text-mainPink hover:text-mainPink',
			outline_white:
				'border-white border text-white [&.button-link>*]:text-white [&.button-link:disabled>*]:text-davyGrey hover:border-mainPink disabled:hover:bg-transparent hover:bg-mainPink [&.button-link>*]:hover:text-black [&.button-link:disabled>*]:hover:text-davyGrey hover:text-black',
			outline_black:
				'border-black border text-black [&.button-link>*]:text-black [&.button-link:disabled>*]:text-davyGrey hover:border-mainPink disabled:hover:bg-transparent hover:bg-mainPink [&.button-link>*]:hover:text-black [&.button-link:disabled>*]:hover:text-davyGrey hover:text-black',
			withoutBg: 'bg-black/30 uppercase [&>span]:text-white rounded-full cursor-pointer hover:bg-mainPink transition-colors duration-300 ease-in-out',
		},
		single: {
			true: 'flex justify-center items-center h-[3.8rem] w-[3.8rem] md:h-[4.8rem] md:w-[4.8rem] after:absolute after:text-s-mobile after:md:text-base after:ml-[0] after:md:ml-[0] shrink-0',
		},
		arrow: {
			true: "after:content-['↗'] after:text-current-color after:text-icon-mobile after:md:text-icon after:font-familjen after:ml-[0.2rem] after:md:ml-[0.4rem] hover:after:md:ml-1 group-hover:after:md:ml-1 after:transition-all",
		},
		disabled: {
			true: 'border-davyGrey hover:border-davyGrey cursor-not-allowed after:text-davyGrey after:md:text-davyGrey',
		},
	},
})

export type ButtonVariants = VariantProps<typeof button>
