import { VariantProps } from 'tailwind-variants'
import { tv } from '@otomoro/helpers/mergeClasses'

export const tag = tv({
	base: "block text-xs-mobile md:text-s font-medium font-helvetica w-fit rounded-full border-[1.5px] px-[1.2rem] py-[0.8rem]",
	variants: {
		theme: {
			dark: 'border-white text-white',
			light: 'border-black text-black',
			pink: 'border-black text-black',
		},
	},
})

export type TagVariants = VariantProps<typeof tag>
