import clsx from 'clsx'

interface IconProps {
	className?: string
	color?: string
	orientation?: 'left' | 'right' | 'top-right'
	height?: number
	width?: number
}

export const Arrow: React.FC<IconProps> = ({
	className,
	color = '#fff',
	orientation = 'right',
	height = 9,
	width = 9,
}) => {
	if (orientation === 'top-right') {
		return (
			<svg
				width={width}
				height={height}
				viewBox="0 0 9 9"
				className={clsx('absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2', className)}
				fill={color}
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0.859375 6.96211L5.72188 2.11211L5.50938 1.89961C5.28438 1.94961 5.08021 1.98294 4.89688 1.99961C4.72188 2.00794 4.55104 2.01211 4.38438 2.01211H2.29688V0.412109H8.60938V6.73711H7.02188V4.64961C7.02188 4.47461 7.02604 4.29961 7.03438 4.12461C7.04271 3.94128 7.07604 3.74128 7.13438 3.52461L6.92188 3.31211L2.05937 8.17461L0.859375 6.96211Z"
					fill={color}
				/>
			</svg>
		)
	}

	if (orientation === 'left') {
		return (
			<svg
				width="11"
				height="10"
				viewBox="0 0 11 10"
				className={clsx(
					'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1.1rem] w-2',
					className
				)}
				fill={color}
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M10.1506 5.61997H2.81742L5.66563 8.46819L4.77066 9.36316L0.394531 4.98703L4.77066 0.610901L5.66563 1.50587L2.81742 4.35409L10.1506 4.35409V5.61997Z"
					fill={color}
				/>
			</svg>
		)
	}

	return (
		<svg
			width="11"
			height="10"
			viewBox="0 0 11 10"
			xmlns="http://www.w3.org/2000/svg"
			className={clsx(
				'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1.1rem] w-2',
				className
			)}
			fill={color}
		>
			<path
				d="M0.849371 4.35391H8.18258L5.33437 1.50569L6.22934 0.610718L10.6055 4.98685L6.22934 9.36298L5.33437 8.468L8.18258 5.61978L0.849371 5.61978V4.35391Z"
				fill={color}
			/>
		</svg>
	)
}
