import clsx from "clsx";
import { createElement, CSSProperties, HTMLAttributes, ReactNode } from 'react'
import { TagVariants, tag } from "./Tag.css";

export interface TagProps extends HTMLAttributes<Element>, TagVariants {
	as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'strong' | 'span' // defaults to h1
	children: ReactNode
	ellipsis?: boolean
	textTransform?: CSSProperties['textTransform']
	fontStyle?: CSSProperties['fontStyle']
	theme?: 'light' | 'dark' | 'pink'
}

export const Tag = ({
	as = 'span',
	children,
	ellipsis = false,
	textTransform,
	theme = "light",
	...rest
}: TagProps) => {
	return createElement(
		as,
		{
			...rest,
			style: {
				...(ellipsis
					? {
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
					}
					: {}),
				...rest.style,
			},
			className: clsx(tag({ theme }), { [`${textTransform}`]: textTransform }, rest.className),
		},
		children
	)
}
