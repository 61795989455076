import Link, { LinkProps } from 'next/link'
import {
	cloneElement,
	createElement,
	forwardRef,
	HTMLAttributes,
	ReactChild,
	ReactComponentElement,
	ReactNode,
	Ref,
} from 'react'
import { AppText } from '@otomoro/components/shared/AppText'
import clsx from 'clsx'
import { button, ButtonVariants } from './Button.css'

export type SanityButtonProps =
	| ((Sanity.Default.Schema.LinkInternal | Sanity.Default.Schema.LinkExternal) & {
			_type: string
			pageType?: string
			slug?: string
			url?: string
			newWindow?: boolean
	  })
	| null

export type DownloadInfoProps = {
	title: string
	fileUrl: string
	fileName: string
	tag: string
}

export interface ButtonProps extends HTMLAttributes<Element>, ButtonVariants {
	as?: 'button' | 'a' | 'nextLink' | 'div' | 'label' // defaults to button
	type?: 'button' | 'submit' | 'reset'
	children?: string | ReactNode
	disabled?: boolean
	arrow?: boolean
	forhtml?: string
	href?: string
	download?: boolean
	iconLeft?: Element | ReactNode
	iconRight?: Element | ReactNode
	inline?: boolean
	loading?: boolean
	nextLinkProps?: Partial<LinkProps>
	target?: '_blank' | '_self' | '_parent' | '_top'
	ellipsis?: boolean
	whitespace?: 'normal' | 'nowrap' | 'pre'
	size?: 'small' | 'normal' | 'gallery' | 'singleSmall'
	fontSize?: 'button' | 'small' | 'medium' | 'large'
	fontFamily?: 'familjen' | 'helvetica'
}

export const Button = forwardRef(
	(
		{
			as = 'button',
			children,
			disabled = false,
			arrow = true,
			forhtml,
			href,
			iconLeft,
			iconRight,
			inline = false,
			loading = false,
			nextLinkProps,
			download,
			onClick,
			type = 'button',
			variant = 'primary',
			size = 'normal',
			fontSize = 'button',
			fontFamily = 'familjen',
			single,
			className,
			whitespace,
			ellipsis = false,
			...rest
		}: ButtonProps,
		ref: Ref<any>
	) => {
		const isDisabled = loading || disabled

		const cloneIconElement = (iconComponent: any) =>
			cloneElement(iconComponent as any, {
				backgroundColor: 'transparent',
				key: 'icon',
				...iconComponent.props,
			})

		const childrenArray: any[] = []

		if (children)
			Array.isArray(children)
				? childrenArray.push(...children)
				: childrenArray.push(
						<AppText
							style={{ zIndex: 1, position: 'relative' }}
							key="text"
							whitespace={whitespace}
							textAlign="center"
							family={fontFamily}
							ellipsis={ellipsis}
							weight="medium"
							size={fontSize}
							color={variant.includes('secondary') ? 'white' : 'black'}
						>
							{children}
						</AppText>
					)

		if (iconLeft)
			childrenArray.unshift(
				<span key="iconLeft" className="mr-[0.5rem]">
					{cloneIconElement(iconLeft)}
				</span>
			)
		if (iconRight)
			childrenArray.push(
				<span key="iconRight" className="ml-[0.5rem]">
					{cloneIconElement(iconRight)}
				</span>
			)

		if (as === 'nextLink' && href) {
			return (
				<Link
					ref={ref}
					href={href}
					className={clsx(
						button({ variant, single, disabled, arrow: !single && arrow, size }),
						'button-link',
						className
					)}
					aria-disabled={disabled}
					onClick={isDisabled ? undefined : onClick}
					type={type}
					download={href && download}
					{...nextLinkProps}
					{...rest}
				>
					{childrenArray}
				</Link>
			)
		}

		return createElement(
			as,
			{
				href,
				ref,
				className: clsx(
					button({ variant, single, disabled, arrow: !single && arrow, size }),
					className,
					'button-link'
				),
				download: href && download,
				disabled: isDisabled,
				forhtml,
				onClick: isDisabled ? undefined : onClick,
				type,
				...rest,
			},
			childrenArray
		)
	}
)

Button.displayName = 'Button'
