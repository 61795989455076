import clsx from 'clsx'
import { createElement, CSSProperties, forwardRef, HTMLAttributes, ReactNode } from 'react'
import { title, TitleVariants } from './AppTitle.css'

export interface AppTitleProps extends HTMLAttributes<Element>, TitleVariants {
	as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'strong' | 'span' // defaults to h1
	children: ReactNode
	ellipsis?: boolean
	textAlign?: CSSProperties['textAlign']
	textTransform?: CSSProperties['textTransform']
	whitespace?: 'normal' | 'nowrap' | 'pre'
	color?: string
	fullWidth?: boolean
}

export const AppTitle = forwardRef<Element, AppTitleProps>(
	(
		{
			as = 'h1',
			children,
			ellipsis = false,
			textAlign,
			textTransform,
			variant = 'h1',
			whitespace,
			color,
			fullWidth,
			...rest
		},
		ref
	) => {
		return createElement(
			as,
			{
				...rest,
				ref,
				style: {
					...(ellipsis
						? {
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
							}
						: {}),
					...rest.style,
				},
				className: clsx(
					title({ variant }),
					{ 'w-full': fullWidth },
					{ [`${textTransform}`]: textTransform },
					{ [`whitespace-${whitespace}`]: whitespace },
					{ [`text-${textAlign}`]: textAlign },
					{ [`text-${color}`]: color },
					rest.className
				),
			},
			children
		)
	}
)

AppTitle.displayName = 'AppTitle'
