import { SANITY_API_VERSION, STUDIO_BASE_PATH, SANITY_DATASET, SANITY_PROJECT_ID } from './sanity.api'
import { createClient } from 'next-sanity'

// export function getClient(preview?: { token: string }) {
  export const client = createClient({
    projectId: SANITY_PROJECT_ID,
    dataset: SANITY_DATASET,
    apiVersion: SANITY_API_VERSION,
    useCdn: false,
    perspective: 'published',
		stega: {
			enabled: false,
    	studioUrl: STUDIO_BASE_PATH,
		}
    // stega: {
    //   enabled:
    //     process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' ||
    //     typeof preview?.token === 'string',
    //   studioUrl: STUDIO_BASE_PATH,
    //   logger: console,
    //   filter: (props) => {
    //     if (typeof props.sourcePath.at(-1) === 'number') {
    //       return false
    //     }
    //     if (props.sourcePath.at(0) === 'duration') {
    //       return false
    //     }
    //     switch (props.sourcePath.at(-1)) {
    //       case 'site':
    //         return false
    //     }
    //     return props.filterDefault(props)
    //   },
    // },
  })
  // if (preview) {
  //   if (!preview.token) {
  //     throw new Error('You must provide a token to preview drafts')
  //   }
  //   return client.withConfig({
  //     token: preview.token,
  //     useCdn: false,
  //     ignoreBrowserTokenWarning: true,
  //     perspective: 'previewDrafts',
  //   })
  // }
//   return client
// }
