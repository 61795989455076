import { VariantProps } from 'tailwind-variants'
import { tv } from '@otomoro/helpers/mergeClasses'

export const title = tv({
	base: 'text-black font-condensed',
	variants: {
		variant: {
			intro: 'text-intro-mobile md:text-intro',
			h3: 'text-h3-mobile md:text-h3',
			h2: 'text-h2-mobile md:text-h2',
			h1: 'text-h1-mobile md:text-h1',
			headline: 'text-h1-mobile md:text-headline',
			headlineSmall: 'text-headline-small',
			headlineBig: 'text-headline-small lg:text-headline-big',
		},
	},
})

export type TitleVariants = VariantProps<typeof title>
