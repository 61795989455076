import imageUrlBuilder from '@sanity/image-url'
import { getImageDimensions, getImage } from '@sanity/asset-utils'
import { client } from '@otomoro/sanity/lib/sanity.client'
import { AppText } from '@otomoro/components/shared/AppText'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import Image, { ImageProps } from 'next/image'
import clsx from "clsx"

type Props = Omit<ImageProps, 'objectFit' | 'src'> & {
	image: SanityImageSource & {
		lqip?: string
		url?: string
		filename?: string
		hotspot?: {
			x: number
			y: number
		},
		crop?: {
			top: number
			bottom: number
			left: number
			right: number
		}
	}
	alt?: string
	width?: number
	height?: number
	className?: string
	caption?: string | undefined
	figureClass?: string | undefined
	captionClass?: string | undefined
	revealCaption?: boolean
	download?: boolean
	showCopyright?: boolean
	hideCaption?: boolean
}

const styles = {
	figureStyle: '',
	imageStyle: '',
	figcaptionStyle: '',
	revealCaption: '',
	captionText: ''
}

const builder = imageUrlBuilder(client)

export const SanityImage = ({
	image,
	alt = '',
	className,
	width,
	height,
	caption,
	figureClass,
	captionClass,
	revealCaption = false,
	download = false,
	showCopyright,
	hideCaption = false,
	...rest
}: Props) => {
	let { width: imageWidth, height: imageHeight } = getImageDimensions(image as any)

	let downloadData = image.url
	if (download && !downloadData) {
		downloadData = getImage(image as any, {
			projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || '',
			dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || '',
		}).asset.url
	}

	if (caption || figureClass || download) {
		return (
			<figure
				className={clsx(
					styles.figureStyle,
					revealCaption || download ? 'is-relative' : '',
					figureClass
				)}
			>
				<Image
					src={builder
						.image(image)
						.width(width || imageWidth)
						.height(height || imageHeight)
						.url()}
					alt={alt}
					width={width || imageWidth}
					height={height || imageHeight}
					placeholder={image.lqip && (width || imageWidth) > 40 ? 'blur' : 'empty'}
					blurDataURL={image.lqip ? image.lqip : undefined}
					style={{
						objectFit: 'cover',
						objectPosition: image?.hotspot ? `${image.hotspot.x * 100}% ${image.hotspot.y * 100}%` : 'center'
					}}
					className={clsx(styles.imageStyle, className)}
					{...rest}
				/>
				{caption && !hideCaption && (
					<figcaption
						className={clsx(
							styles.figcaptionStyle,
							captionClass,
							revealCaption ? styles.revealCaption : undefined
						)}
					>
						<AppText
							color={revealCaption ? 'white' : undefined}
							className={clsx(styles.captionText, captionClass)}
						>
							{caption}
						</AppText>
					</figcaption>
				)}
			</figure>
		)
	}

	return (
		<Image
			src={builder
				.image(image)
				.width(width || imageWidth)
				.height(height || imageHeight)
				.url()}
			alt={alt}
			width={width || imageWidth}
			height={height || imageHeight}
			style={{
				objectFit: 'cover',
				objectPosition: image?.hotspot ? `${image.hotspot.x * 100}% ${image.hotspot.y * 100}%` : 'center'
			}}
			className={
				clsx(
					styles.imageStyle,
					className,
				)
			}
			placeholder={image.lqip && (width || imageWidth) > 40 ? 'blur' : 'empty'}
			blurDataURL={image.lqip ? image.lqip : undefined}
			{...rest}
		/>
	)
}
