export function resolveHref(documentType?: string, slug?: string, anchor?: string): string | undefined {
	switch (documentType) {
		case 'homePage':
		return `/${anchor ? '#' + anchor : ''}`
		case 'technologyPage':
			return `/technology${anchor ? '#' + anchor : ''}`
		case 'impactPage':
			return `/impact${anchor ? '#' + anchor : ''}`
		case 'companyPage':
			return `/company${anchor ? '#' + anchor : ''}`
		case 'newsPage':
			return `/news${anchor ? '#' + anchor : ''}`
		case 'contactPage':
			return `/contact${anchor ? '#' + anchor : ''}`
		case 'privacyPage':
			return `/privacy-policy${anchor ? '#' + anchor : ''}`
		case 'termsPage':
			return `/terms-and-conditions${anchor ? '#' + anchor : ''}`
		case 'marketPages':
			return `/markets/${slug}${anchor ? '#' + anchor : ''}`
		case 'productPages':
			return slug ? `/products/${slug}${anchor ? '#' + anchor : ''}` : undefined
		case 'teams':
			return slug ? `/company/${slug}${anchor ? '#' + anchor : ''}` : undefined
		case 'newsPages':
			return slug ? `/news/${slug}${anchor ? '#' + anchor : ''}` : undefined
		default:
			console.warn('Invalid document type:', documentType)
			return undefined
	}
}
